import React from 'react';
const data = {
  pt:{ 
    subtittle:"Introdução: ",
    textone:
      <span>
        Em 2013, o Ministério da Saúde, por meio da Área Técnica
        responsável pela Política Nacional de Atenção Integral às Pessoas
        com Doença Falciforme, da Coordenação Geral de Sangue e
        Hemoderivados, na Secretaria de Atenção Especializada à Saúde
        (SAES) estabeleceu o Termo de Cooperação 222/2012 com a
        Universidade Federal do Rio de Janeiro (UFRJ), alusivo ao projeto{" "}
        <strong
          style={{ color: "rgb(234, 47, 43)", fontStyle: "italic" }}
        >
          Estudo e Pesquisa sobre Doenças Hematológicas e Capacitação de
          Profissionais que atuem na Atenção aos Pacientes Portadores de
          Doenças Hematológicas
        </strong>
        , processo 25000.197332/2012-71, coordenado por mim e executado
        pela Faculdade de Odontologia (FO) com recursos do Fundo Nacional
        de Saúde - Ministério da Saúde – Brasil. Isto viabilizou que
        Estudos e Pesquisas sobre Doenças Hematológicas fossem liderados
        por mim, contando com a colaboração de diferentes parceiros na
        UFRJ. Para atenção odontológica qualificada as pessoas com Doença
        Falciforme, considerar a assistência farmacêutica é estratégica.
        Assim, com as Profa Maria Clara Canelas, Profa Dra Luciana Serrão,
        com o aluno de Iniciação Científica Paulo Martins e da aluna de
        Iniciação Científica Fábia Michelly, estes da faculdade de
        Farmácia da UFRJ, pesquisamos sobre interações medicamentosas.
        Pelo menos dois trabalhos científicos foram apresentados nas 9a e
        10a edições da Semana de Integração Acadêmica (SIAC) da UFRJ que
        aconteceram em 2018 e 2019, respectivamente.
        <p>
          Em 2020, pensando na possibilidade de aplicação prática dos
          resultados destes estudos, procurei a Fluxo Consultoria. Este
          sistema web é o resultado deste percurso! Sintam-se convidados a
          utilizá-lo!
        </p>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          <i>Um abraço</i>
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "rgb(234, 47, 43)",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Márcia Alves
        </p>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          <i>Doutora em Odontologia</i>
        </p>
      </span>,
    subtittleone:"Aplicação:",
    textthree:"Área da saúde. ",
    textfour:"Indústria farmacêutica.",
    subtittletwo:"Indicação:",
    textfive:"Prescritores de medicamentos.",
    subtittlethree:"Finalidade:",
    textsix:"Evitar a prescrição de medicamentos que sabidamente apresentam interações medicamentosas e, por conseguinte, possam levar à perda da previsibilidade terapêutica, melhorando assim, a qualidade da atenção à saúde das pessoas com doença falciforme.",
    subtittlefour:"Autoria:",
    textseven:"Profa Dra Marcia Pereira Alves dos Santos. Ministério da Saúde/FO/CROFAL –– HUCFF - UFRJ",
    subtitlefive:"Colaboradores:",
    texteight:"Profa Maria Clara Canellas da Silva. Faculdade de Farmácia - UFRJ",
    textnine:"Profa Dra Luciana Wermelinger Serrão. Faculdade de Farmácia - UFRJ",
    textten:" Acadêmica Fabia Michelly Ferreira. Voluntária  de Iniciação Científica no projeto de pesquisa – Faculdade de Farmácia - UFRJ",
    texteleven:"Acadêmico Paulo Henrique Martins. Bolsista Iniciação Científica no projeto – Faculdade de Farmácia - UFRJ",
    finaltextone:"Mais Informações:",
    finaltexttwo:"dramarciaalves@gmail.com",
  },
  en:{
    subtittle:"Introduction:",
    textone:
      <span>
        In 2013, the Ministry of Health, through the Technical Area
        responsible for the National Policy for Comprehensive Care for
        People with Sickle Cell Disease, of the General Coordination of
        Blood and Blood Products, of the Secretariat of Specialized Health
        Care (SAES) established the 222 Cooperation Term / 2012 with the
        Federal University of Rio de Janeiro(UFRJ), allusive to the
        project{" "}
        <strong
          style={{ color: "rgb(234, 47, 43)", fontStyle: "italic" }}
        >
          Study and Research on Hematological Diseases and Training of
          Professionals who work in the Care of Patients with
          Hematological Diseases
        </strong>
        , process 25000.197332/2012-71, coordinated by me and executed by
        the Faculty of Dentistry (FO) with resources from the National
        Health Fund - Ministry of Health - Brazil. This made it possible
        for Studies and Research on Hematological Diseases to be led by
        me, with the collaboration of different partners at UFRJ. For
        qualified dental care for people with sickle cell disease,
        considering pharmaceutical assistance is strategic. Thus, with
        Prof. Maria Clara Canelas, Prof. Dr. Luciana Serrão, with the
        Scientific Initiation student Paulo Martins and the Scientific
        Initiation student Fábia Michelly, these from the Faculty of
        Pharmacy at UFRJ, we researched drug interactions. At least two
        scientific papers were presented in the 9th and 10th editions of
        the Academic Integration Week (SIAC) at UFRJ that took place in
        2018 and 2019, respectively.
        <p>
          In 2020, thinking about the possibility of the practical
          application of the results of these studies, I sought out Fluxo
          Consultoria. This web system is the result of this journey! Feel
          invited to use it!
        </p>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          <i>A hug</i>
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "rgb(234, 47, 43)",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Márcia Alves
        </p>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          <i>Ph.D. in Dentistry</i>
        </p>
      </span>,
    subtittleone:"Application:",
    textthree:"Health Area.",
    textfour:"Pharmaceutical Industry.",
    subtittletwo:"Recommendation:",
    textfive:"Medication prescribers.",
    subtittlethree:"Goal:",
    textsix:"Avoid prescribing medicines that are known to have drug interactions, and therefore may lead to loss of therapeutic predictability, thus improving the quality of care for people with sickle cell disease.",
    subtittlefour:"Authorship:",
    textseven:"Prof Dra Marcia Pereira Alves dos Santos. Ministry of Health/FO/CROFAL –– HUCFF - UFRJ",
    subtitlefive:"Collaborators:",
    texteight:"Prof Maria Clara Canellas da Silva. Pharmacy College - UFRJ",
    textnine:"Prof Dra Luciana Wermelinger Serrão. Pharmacy College - UFRJ",
    textten:"Fabia Michelly Ferreira. Scientific Initiation Volunteer in the research project – Pharmacy College - UFRJ",
    texteleven:"Paulo Henrique Martins. Scientific Initiation Scholarship in the project – Pharmacy College - UFRJ",
    texttwelve:"Pof Dra Marcia Pereira Alves Dos Santos. ",
    finaltextone:"More information:",
    finaltexttwo:"dramarciaalves@gmail.com",
  },
  es:{
    subtittle:"Introducción:",
    textone:
      <span>
        En 2013, el Ministerio de Salud, a través del Área Técnica
        responsable de la Política Nacional de Atención Integral a las
        Personas con Enfermedad Drepanocítica, de la Coordinación General
        de Sangre y Productos Sanguíneos, en la Secretaría de Atención
        Médica Especializada (SAES) estableció el Término de Cooperación
        222 / 2012 con la Universidad Federal de Río de Janeiro (UFRJ),
        alusiva al proyecto{" "}
        <strong
          style={{ color: "rgb(234, 47, 43)", fontStyle: "italic" }}
        >
          Estudio e Investigación sobre Enfermedades Hematológicas y
          Capacitación de Profesionales que Trabajan en la Atención de
          Pacientes con Enfermedades Hematológicas
        </strong>
        , proceso 25000.197332/2012-71, coordinado por mí y ejecutado por
        la Facultad de Odontología (FO) con recursos del Fondo Nacional de
        Salud - Ministerio de Salud - Brasil. Esto hizo posible que los
        Estudios e Investigaciones sobre Enfermedades Hematológicas sean
        liderados por mí, con la colaboración de diferentes socios de la
        UFRJ. Para la atención dental calificada para personas con
        enfermedad de células falciformes, considerar la asistencia
        farmacéutica es estratégico. Así, con la profesora Maria Clara
        Canelas, la profesora Dra. Luciana Serrão, con el estudiante de
        Iniciación Científica Paulo Martins y la estudiante de Iniciación
        Científica Fábia Michelly, estos de la Facultad de Farmacia de la
        UFRJ, investigamos las interacciones entre medicamentos. Se
        presentaron al menos dos artículos científicos en la novena y
        décima edición de la Semana de Integración Académica (SIAC) de la
        UFRJ que tuvo lugar en 2018 y 2019, respectivamente.
        <p>
          En 2020, pensando en la posibilidad de una aplicación práctica
          de los resultados de estos estudios, busqué Fluxo Consultoria.
          ¡Este sistema web es el resultado de este viaje! ¡Siéntete
          invitado a usarlo!
        </p>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          <i>Un abrazo</i>
        </p>
        <p
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "rgb(234, 47, 43)",
            fontWeight: "bold",
            fontStyle: "italic",
          }}
        >
          Márcia Alves
        </p>
        <p style={{ display: "flex", justifyContent: "flex-end" }}>
          <i>Doctora en Odontologia</i>
        </p>
      </span>,
    subtittleone:"Aplicación:",
    textthree:"Área de Salud.",
    textfour:"Industria Farmacéutica.",
    subtittletwo:"Recomendación:",
    textfive:"Prescriptores de medicamentos.",
    subtittlethree:"Meta:",
    textsix:"Evite recetar medicamentos que se sabe que tienen interacciones con otros medicamentos y que, por lo tanto, pueden provocar la pérdida de la capacidad terapéutica.  previsibilidad, mejorando así la calidad de la atención a las personas con anemia drepanocítica.",
    subtittlefour:"Autoría:",
    textseven:"Prof Dra Marcia Pereira Alves dos Santos. Ministerio de Salud/FO/CROFAL –– HUCFF - UFRJ",
    subtitlefive:"Colaboradores:",
    texteight:"Prof Maria Clara Canellas da Silva. Facultad de Farmacia - UFRJ",
    textnine:"Prof Dra Luciana Wermelinger Serrão. Facultad de Farmacia - UFRJ",
    textten:"Fabia Michelly Ferreira. Voluntario de iniciación científica en el proyecto de investigación. – Facultad de Farmacia - UFRJ",
    texteleven:"Paulo Henrique Martins. Beca de iniciación científica en el proyecto. – Facultad de Farmacia - UFRJ",
    texttwelve:"Pof Dra Marcia Pereira Alves Dos Santos. ",
    finaltextone:"Más información:",
    finaltexttwo:"dramarciaalves@gmail.com",
  }
} 

export default data;
