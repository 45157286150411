import styled from "styled-components";
import Logo from "../../Imagens/IMFalciforme.png";

export const StyledLogoDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 31vh;
  width: 50%;
  background: none;
  background-image: url(${Logo});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  margin-left: 1%;
  border: none;
  border-color: none;
  margin-top: 6%;

  @media (max-width: 650px) {
    width: 95%;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    margin-top: 0;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0;
  width: 50%;
  border: none;
  border-color: none;
  margin-left: 1%;

  @media (max-width: 650px) {
    width: 95%;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    margin-bottom: 40px;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0;
  height: 55px;
  width: 65%;
  border: none;
  border-color: none;

  @media (max-width: 650px){
  width:95%;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1%;
  font-size: large;
  margin-top: 1%;
  height: 30px;
  width: 65%;
  border: none;
  border-color: none;
  font-family: Courier New;
  font-weight: bold;
  color: rgb(78, 8, 13);

  @media (max-width: 650px) {
    margin-bottom: 10px;
    margin-left: 8px;
    text-align: left;
    width: 95%;
  }
`;

export const StyledInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: #f3f3f3;
  padding: 0;
  margin: 0;
  color: black;
  border: none;
  border-color: black;
  border-width: 100%;
  border-radius: 5px;
  font-size: large;
  height: 100%;
  width: 90%;
`;

export const StyledDivButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background: none;
  padding: 0;
  margin: 0;
  height: 45px;
  width: 65%;
  border: none;
  border-color: none;

  @media (max-width: 650px){
  width: 95%;
  }
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border-radius: 7px;
  border-color: rgb(226, 75, 55);
  background-color: rgb(226, 75, 55);
  color: white;
  text-transform: uppercase;
  font-size: 90%;
  height: 80%;
  width: 40%;
  margin-top: 2%;
  &:hover {
    background: rgb(255, 0, 0);
    border: rgb(255, 0, 0);
    box-shadow: 0 12px 22px rgba(0, 0, 0, 0.19), 0 10px 10px rgba(0, 0, 0, 0.23);
  }

  @media (max-width: 650px) {
    margin-top: 10px;
  }
`;

export const StyledErrorMessage = styled.p`
  width: 70%;
  font-style: italic;
  text-align: center;
  color: rgb(78, 8, 13);
  font-weight: bold;
  font-size: 95%;
`;
