import React, { Component } from "react";
import api from "../../Services/Api";
import { StyledDiv } from "./Style";
import { Styledtable } from "./Style";
import { Styledtd } from "./Style";
import { Styledtr } from "./Style";
import { Styledth } from "./Style";
import { StyledtTableContent } from "./Style";
import { StyledIcon } from "./Style";
import Check from "../../Imagens/checkGreen.png";
import False from "../../Imagens/falseRed3.png";
import Loader from "../Loader/Loader";
import TableFooter from "./TableFooter";

class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datalist: [],
      loading: true,
    };
  }

  async LoadingTableData() {
    var urlinicial;
    // eslint-disable-next-line
    if (this.props.languageThree == "pt") {
      urlinicial = "pt/drugsinfo/";
    }
    // eslint-disable-next-line
    if (this.props.languageThree == "es") {
      urlinicial = "es/drugsinfo/";
    }
    // eslint-disable-next-line
    if (this.props.languageThree == "en") {
      urlinicial = "en/drugsinfo/";
    }
    var urlfinal = localStorage.getItem("@sw-marcia/") + "/";
    var url = urlinicial + urlfinal;
    const response = await api.get(url);
    this.setState({ loading: false });
    this.setState({ datalist: response.data });
  }

  componentDidMount() {
    this.LoadingTableData();
  }

  renderTableData() {
    return this.state.datalist.map((data, index) => {
      var {
        Nome,
        Acido_Folico,
        Hidroxiureia,
        Penicilina_V,
        Sulfato_de_Ferro,
        Diclofenaco,
        Acetaminofeno,
        IM_Total,
      } = data;

      function verification(variable) {
        // eslint-disable-next-line
        if (variable == true) {
          variable = <StyledIcon width="80%" src={Check} alt="Check" />;
        } else {
          variable = <StyledIcon width="80%" src={False} alt="False" />;
        }
        return variable;
      }

      Acido_Folico = verification(Acido_Folico);
      Hidroxiureia = verification(Hidroxiureia);
      Penicilina_V = verification(Penicilina_V);
      Sulfato_de_Ferro = verification(Sulfato_de_Ferro);
      Acetaminofeno = verification(Acetaminofeno);
      Diclofenaco = verification(Diclofenaco);

      return (
        <Styledtr key={Nome}>
          <Styledtd textalign="left">{Nome}</Styledtd>
          <Styledtd textalign="center">{Acido_Folico}</Styledtd>
          <Styledtd textalign="center">{Hidroxiureia}</Styledtd>
          <Styledtd textalign="center">{Penicilina_V}</Styledtd>
          <Styledtd textalign="center">{Sulfato_de_Ferro}</Styledtd>
          <Styledtd textalign="center">{Diclofenaco}</Styledtd>
          <Styledtd textalign="center">{Acetaminofeno}</Styledtd>
          <Styledtd textalign="center">{IM_Total}</Styledtd>
        </Styledtr>
      );
    });
  }

  render() {
    // eslint-disable-next-line
    if (this.props.languageThree == "pt") {
      var Coluna1 = "Nome";
      var Coluna2 = "Ácido Fólico";
      var Coluna3 = "Hidroxiureia";
      var Coluna4 = "Penicilina V";
      var Coluna5 = "Sulfato de Ferro";
      var Coluna6 = "Diclofenaco";
      var Coluna7 = "Acetaminofeno";
      var Coluna8 = "IM Total";
      // eslint-disable-next-line
    } else if (this.props.languageThree == "en") {
      Coluna1 = "Name";
      Coluna2 = "Folic acid";
      Coluna3 = "Hydroxyurea";
      Coluna4 = "Penicilin V";
      Coluna5 = "Ferrous Sulphate";
      Coluna6 = "Diclofenac";
      Coluna7 = "Acetaminophen";
      Coluna8 = "IM Total";

      // eslint-disable-next-line
    } else {
      Coluna1 = "Nome";
      Coluna2 = "Ácido Fólico";
      Coluna3 = "Hidroxiurea";
      Coluna4 = "Penicilina V";
      Coluna5 = "Sulfato de Hierro";
      Coluna6 = "Diclofenaco";
      Coluna7 = "Acetaminofeno";
      Coluna8 = "IM Total";
    }

    return (
      <StyledDiv onClick={this.props.click}>
        <StyledtTableContent>
          <Styledtable>
            {this.state.loading ? (
              <Loader />
            ) : (
              <Styledtr>
                <Styledth> {Coluna1} </Styledth>
                <Styledth> {Coluna2} </Styledth>
                <Styledth> {Coluna3} </Styledth>
                <Styledth> {Coluna4} </Styledth>
                <Styledth> {Coluna5} </Styledth>
                <Styledth> {Coluna6} </Styledth>
                <Styledth> {Coluna7} </Styledth>
                <Styledth> {Coluna8} </Styledth>
              </Styledtr>
            )}
            <tbody>{this.renderTableData()}</tbody>
          </Styledtable>
          {this.state.loading ? null : (
            <TableFooter language={this.props.languageThree} />
          )}
        </StyledtTableContent>
      </StyledDiv>
    );
  }
}

export default Table;
