import styled from "styled-components";

// Menu Inferior //
export const StyledFooter = styled.footer`
  display: flex;
  height: 70px;
  flex-direction: row;
  align-items: center;
  background-color: #414141;
  width: 100%;
`;

// Div - Engloba todos os ícones diferentes de Países. //
export const StyledAllOtherIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 25%;
`;

// Div - Engloba todos os ícones diferentes de Países. //
export const StyledMiddleDiv = styled.div`
  display: flex;
  width: 15%;
`;

// Div - Engloba todos os ícones de Países e os botoões: "Como Usar" & "Saiba Mais". //
export const StyledAllCountryIcons = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 60%;

  @media (max-width: 650px) {
    width: 95%;
  }
`;

// Div - Engloba cada ícone do Menu Inferior.//
export const StyledIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-left: ${(props) => props.left};
  margin-right: ${(props) => props.right};
  height: ${(props) => props.height};

  @media (max-width: 650px) {
    margin-left: 0px;
  }
`;

// Div - Estiliza os botões texto do Menu Inferior.  //
export const StyledButtons = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  outline: none;

  font-size: 100%;
  width: ${(props) => props.width};
  cursor: ${(props) => props.cursor};
  margin-top: ${(props) => props.top};
  margin-left: ${(props) => props.left};
  margin-right: ${(props) => props.right};
  background-image: ${(props) => props.image};

  @media (max-width: 650px) {
    min-width: 14%;
    font-size: 100%;
    margin: 0 5px;
    margin-right: ${(props) => props.right};
  }
`;

// Div - Estiliza os ícones do Menu Inferior.//
export const StyledIconContainer = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  outline: none;

  font-size: 100%;
  width: ${(props) => props.width};
  cursor: ${(props) => props.cursor};
  margin-top: ${(props) => props.top};
  margin-left: ${(props) => props.left};
  margin-right: ${(props) => props.right};
  background-image: ${(props) => props.image};
`;

// Img - Estiliza todos os ícones do Menu Inferior.//
export const StyledIcon = styled.img`
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
`;
