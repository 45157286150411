const data = {
  pt: [
    {
      text: "1º Passo: Insira o princípio ativo que deseja validar.",
      image: require("../../Imagens/Input.png"),
      alt: "InputImage",
      width: "35%",
    },
    {
      text: "2º Passo: O selecione na lista.",
      image: require("../../Imagens/DropDownPt.png"),
      alt: "Imagem Do Drop Down.",
      width: "35%",
    },
    {
      text:
        "Observação: Caso o princípio não se encontre no sistema irá aparecer:",
      image: require("../../Imagens/PrincipioNaoEncontrado.png"),
      alt: "Principio Ativo Nao Encontrado.",
      width: "35%",
    },
    {
      text:
        "Observação: Selecione mais do que um único princípio ativo, caso necessite:",
      image: require("../../Imagens/VariosPrincipios.png"),
      alt: "Varios Principios Ativos No Input.",
      width: "35%",
    },
    {
      text: "3º Passo: Clique no botão de Validar.",
      image: require("../../Imagens/BotaoDeValidar.png"),
      alt: "Botão De Validar.",
      width: "18%",
    },
    {
      text: "4º Passo: Interpretaçao da tabela: ",
      image: require("../../Imagens/TablePt.png"),
      alt: "Tabela de Interações.",
      width: "35%",
    },
  ],
  es: [
    {
      text: "1er Paso: Inserte el principio activo que desea validar.",
      image: require("../../Imagens/Input.png"),
      alt: "InputImage",
      width: "35%",
    },
    {
      text: "2er paso: selecciónelo de la lista.",
      image: require("../../Imagens/DropDownEn.png"),
      alt: "Imagem Do Drop Down.",
      width: "35%",
    },
    {
      text: "Nota: Si el principio no está en el sistema, aparecerá:",
      image: require("../../Imagens/PrincipioNoEncontrado.png"),
      alt: "Principio Ativo Nao Encontrado.",
      width: "35%",
    },
    {
      text: "Nota: Seleccione más de un ingrediente activo si necesita:",
      image: require("../../Imagens/VariosPrincipiosEs.png"),
      alt: "Varios Principios Ativos No Input.",
      width: "35%",
    },
    {
      text: "3er paso: haga clic en el botón Validar.",
      image: require("../../Imagens/BotaoDeValidar.png"),
      alt: "Botão De Validar.",
      width: "18%",
    },
    {
      text: "4er Paso: Interpretación de la tabla:",
      image: require("../../Imagens/TableEs.png"),
      alt: "Tabela de Interações.",
      width: "35%",
    },
  ],
  en: [
    {
      text: "1st Step: Enter the active principle you want to validate.",
      image: require("../../Imagens/Input.png"),
      alt: "Input Image",
      width: "35%",
    },
    {
      text: "2nd Step: Select it from the list.",
      image: require("../../Imagens/DropDownEn.png"),
      alt: "Drop Down Image.",
      width: "35%",
    },
    {
      text: "Note: If the principle is not in the system, it will appear:",
      image: require("../../Imagens/PrincipleNotFound.png"),
      alt: "Active Principle Not Found.",
      width: "35%",
    },
    {
      text: "Note: Select more than a single active principle if you need to:",
      image: require("../../Imagens/MultiplePrinciples.png"),
      alt: "Multiple Active Principles on the Input",
      width: "35%",
    },
    {
      text: "3rd Step: Click on Validate button.",
      image: require("../../Imagens/ValidateButton.png"),
      alt: "ValidateButtonImage",
      width: "18%",
    },
    {
      text: "4th Step: Table Interpretation.",
      image: require("../../Imagens/TableEn.png"),
      alt: "Table",
      width: "35%",
    },
  ],
};

export default data;
