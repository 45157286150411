import React, { Component } from "react";
import api from "../../Services/Api";
import AsyncSelect from "react-select/async";
import {
  StyledLogoDiv,
  StyledForm,
  StyledDiv,
  StyledLabel,
  StyledDivButtons,
  StyledButton,
  StyledErrorMessage,
} from "./Style";
import { customStyles } from "./customStyles";

import Table from "../Table/Table";
import Loader from "../Loader/Loader";

class MainInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drugsList: [],
      selectedOptions: null,
      showTable: false,
      errorOnApiFetch: null,

      isOnLandscapeMode: false,
      isMenuOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.showTable = this.showTable.bind(this);
    this.hideTable = this.hideTable.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  async fetchDrugsFromApi() {
    this.setState({ errorOnApiFetch: false });
    this.props.startLoading();
    try {
      const response = await api.get(this.props.formsLanguageData.url);
      response.data.map((index) =>
        this.setState({
          drugsList: [...this.state.drugsList, { value: index, label: index }],
        })
      );
    } catch (error) {
      this.setState({ errorOnApiFetch: true });
    } finally {
      this.props.finishLoading();
    }
  }

  componentDidMount() {
    const handleOrientationChange = () => {
      this.setState({ isOnLandscapeMode: false });

      const orientation = window.screen.orientation.type;
      const width = window.screen.width;
      if (orientation === "landscape-primary" && width < 1000) {
        this.setState({ isOnLandscapeMode: true });
      }
    };

    window.addEventListener("orientationchange", handleOrientationChange);
    this.fetchDrugsFromApi();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.formsLanguageData.url !== this.props.formsLanguageData.url) {
      this.setState({ drugsList: [] });
      this.fetchDrugsFromApi();
      this.setState({ selectedOptions: null });
      this.renderSelect();
    }
  }

  filter = (inputValue) =>
    this.state.drugsList.filter((option) =>
      option.label
        .slice(0, [inputValue.length])
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    );

  loadOptions = (inputValue) => {
    return new Promise((resolve, reject) => {
      // Using setTimeout to emulate a call to server.
      setTimeout(() => {
        resolve(this.filter(inputValue));
      }, 3000);
    });
  };

  handleChange = (selectedOptions) => {
    this.setState({ selectedOptions });
  };

  catchOption = () => {
    var Options, OptionsLen, i, NewList;
    Options = this.state.selectedOptions;
    if (Options !== null) {
      OptionsLen = Options.length;
    }

    NewList = [];
    for (i = 0; i < OptionsLen; i++) {
      NewList.push(Options[i]["value"]);
    }
    return NewList;
  };

  showTable = () => {
    this.setState({ showTable: true });
  };

  hideTable = () => {
    this.setState({ showTable: false });
  };

  handleToggleMenu = () => {
    this.setState({ isMenuOpen: true });
  };

  handleMenuClosed = () => {
    this.setState({ isMenuOpen: false });
  };

  renderSelect = () => {
    return (
      <AsyncSelect
        styles={customStyles}
        defaultOptions={this.state.drugsList}
        loadOptions={this.loadOptions}
        cacheOptions
        isClearable
        isSearchable
        isMulti
        autoFocus={false}
        placeholder=" "
        noOptionsMessage={() => this.props.formsLanguageData.noOptionsMessage}
        loadingMessage={() => this.props.formsLanguageData.loadingMessage}
        menuPlacement="bottom"
        maxMenuHeight="38vh"
        onChange={this.handleChange}
        onMenuOpen={this.handleToggleMenu}
        onMenuClose={this.handleMenuClosed}
      />
    );
  };

  handleClick = () => {
    var Options = this.state.selectedOptions;
    if (Options !== null) {
      if (Options.length === 0) {
        this.hideTable();
      } else {
        this.showTable();
      }
    }

    var localstorage = this.catchOption();
    localStorage.setItem("@sw-marcia/", localstorage);
  };

  render() {
    let showTable = null;

    if (this.state.showTable) {
      showTable = (
        <Table languageThree={this.props.language} click={this.hideTable} />
      );
    }

    return (
      <>
        {this.props.loading ? (
          <Loader />
        ) : (
          <>
            {this.state.isMenuOpen && this.state.isOnLandscapeMode ? null : (
              <StyledLogoDiv />
            )}
            <StyledForm>
              {this.state.isMenuOpen && this.state.isOnLandscapeMode ? null : (
                <StyledLabel>{this.props.formsLanguageData.label}</StyledLabel>
              )}
              <StyledDiv>{this.renderSelect()}</StyledDiv>

              <StyledDivButtons>
                <StyledButton type="button" onClick={this.handleClick}>
                  {this.props.formsLanguageData.textbutton}
                </StyledButton>
              </StyledDivButtons>
              <StyledErrorMessage>
                {this.state.errorOnApiFetch
                  ? "Algum erro ocorreu. Por favor tente novamente."
                  : null}
              </StyledErrorMessage>
            </StyledForm>
            {showTable}
          </>
        )}
      </>
    );
  }
}

export default MainInput;
