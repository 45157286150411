import React, { Component } from "react";
import { Container, Description, Icon, SubContainer } from "./styles";
import Check from "../../../Imagens/checkGreen.png";
import False from "../../../Imagens/falseRed3.png";
import data from "./data";


class TableFooter extends Component {
  render() {
    return (
      <Container>
        <SubContainer>
          <Icon src={Check} alt="Check" />
          <Description>
            {data[this.props.language].checkDescription}
          </Description>
        </SubContainer>
        <SubContainer>
          <Icon src={False} alt="False" />
          <Description>
            {data[this.props.language].falseDescription}
          </Description>
        </SubContainer>
      </Container>
    );
  }
}

export default TableFooter;
