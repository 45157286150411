import React, { Component } from "react";

import { StyledDiv,StyledInsideDiv,StyledTextInsideDiv,StyledText,StyledSubTittle,StyledList } from "./Style";
import data from './data';

class SeeMore extends Component {
  render() {
    return (
      <StyledDiv onClick={this.props.clickone}>
        <StyledInsideDiv>
          <StyledTextInsideDiv>
            <StyledSubTittle marginleft="3.5%">
              {data[this.props.language].subtittle}
            </StyledSubTittle>
            <StyledText>{data[this.props.language].textone}</StyledText>

            <StyledList>
              <StyledSubTittle> {data[this.props.language].subtittleone} </StyledSubTittle>
              <li> {data[this.props.language].textthree} </li>
              <li> {data[this.props.language].textfour} </li>
            </StyledList>

            <StyledList>
              <StyledSubTittle> {data[this.props.language].subtittletwo} </StyledSubTittle>
              <li> {data[this.props.language].textfive} </li>
            </StyledList>

            <StyledList>
              <StyledSubTittle> {data[this.props.language].subtittlethree}</StyledSubTittle>
              <li>{data[this.props.language].textsix}</li>
            </StyledList>

            <StyledList>
              <StyledSubTittle> {data[this.props.language].subtittlefour} </StyledSubTittle>
              <li> {data[this.props.language].textseven} </li>
            </StyledList>
            <StyledList>
              <StyledSubTittle>{data[this.props.language].subtitlefive}</StyledSubTittle>
              <li> {data[this.props.language].texteight} </li>
              <li> {data[this.props.language].textnine} </li>
              <li> {data[this.props.language].textten} </li>
              <li> {data[this.props.language].texteleven} </li>
            </StyledList>
            <StyledList>
              <StyledSubTittle> {data[this.props.language].finaltextone} </StyledSubTittle>
              <li> {data[this.props.language].finaltexttwo} </li>
            </StyledList>
          </StyledTextInsideDiv>
          <div>
            {data[this.props.language].texttwo}
          </div>
        </StyledInsideDiv>
      </StyledDiv>
    );
  }
}

export default SeeMore;
