import React, { Component } from "react";
import "./Style.css";

class Loader extends Component {
  render() {
    return <div className= "loader"> </div>;
  }
}

export default Loader;
