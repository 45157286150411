const data = {
  pt: {
    checkDescription: `Há interação. Os medicamentos NÃO devem ser usados em conjunto.`,
    falseDescription:
      "Não há interação. Os medicamentos podem ser utilizados em conjunto.",
  },
  es: {
    checkDescription: "Hay interacción. Las medicinas NO deben usarse juntas.",
    falseDescription:
      "No hay interaccion. Las medicinas se pueden usar juntas.",
  },
  en: {
    checkDescription:
      "There is interaction. Medicines should NOT be used together.",
    falseDescription:
      "There is no interaction. Medicines can be used together. ",
  },
};

export default data;
