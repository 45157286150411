import React, { Component } from "react";
import styled from "styled-components";
import LowerMenu from "./Components/LowerMenu/LowerMenu";
import FormsTeste from "./Components/MainInput/FormsTeste";
import Background from "./Imagens/Background.png";

const StyledDiv = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const StyledContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border: none;
  border-color: none;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languagePT: true,
      languageEN: false,
      languageES: false,
      loading: true,
    };

    this.handleClickBrazil = this.handleClickBrazil.bind(this);
    this.handleClickSpain = this.handleClickSpain.bind(this);
    this.handleClickUnitedKingdom = this.handleClickUnitedKingdom.bind(this);
  }

  handleClickBrazil = () => {
    this.setState({ languagePT: true });
    this.setState({ languageEN: false });
    this.setState({ languageES: false });
    // eslint-disable-next-line
    if (this.state.languagePT == false) {
      this.setState({ loading: true });
    }
  };

  handleClickSpain = () => {
    this.setState({ languagePT: false });
    this.setState({ languageEN: false });
    this.setState({ languageES: true });
    // eslint-disable-next-line
    if (this.state.languageES == false) {
      this.setState({ loading: true });
    }
  };

  handleClickUnitedKingdom = () => {
    this.setState({ languagePT: false });
    this.setState({ languageEN: true });
    this.setState({ languageES: false });
    // eslint-disable-next-line
    if (this.state.languageEN == false) {
      this.setState({ loading: true });
    }
  };

  render() {
    let languageresult = null;

    if (this.state.languagePT) {
      languageresult = "pt";
    }
    if (this.state.languageES) {
      languageresult = "es";
    }
    if (this.state.languageEN) {
      languageresult = "en";
    }

    return (
      <StyledDiv>
        <StyledContent>
          <FormsTeste
            language={languageresult}
            loading={this.state.loading}
            startLoading={() => {
              this.setState({ loading: true });
            }}
            finishLoading={() => {
              this.setState({ loading: false });
            }}
          />
        </StyledContent>
        <LowerMenu
          ClickBrazil={this.handleClickBrazil}
          ClickSpain={this.handleClickSpain}
          ClickUnitedKingdom={this.handleClickUnitedKingdom}
          language={languageresult}
        />
      </StyledDiv>
    );
  }
}

export default App;
