import axios from "axios";

const api = axios.create({
  baseURL: "https://falciforme.com.br:8080/",

  headers: {
    Authorization: "Token b8ff846a9a55887b6d6bc3baabd312d384bae73d",
  },
});

export default api;
