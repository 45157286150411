export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "50px",
    borderStyle: "none",
    borderColor: "none",
    outline: "none",
    margin: "0",
    padding: "0",
  }),

  control: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(255, 251, 251, 0.171)",
    borderColor: state.isFocused ? "red" : "transparent",
    boxShadow: state.isFocused
      ? "0 12px 22px rgba(0, 0, 0, 0.19), 0 10px 10px rgba(0, 0, 0, 0.23)"
      : "transparent",
    "&:hover": {
      borderColor: state.isFocused ? "red" : "transparent",
    },
    borderRadius: "7px",
    borderStyle: "solid",
    outline: "none",
    fontSize: "100%",
    height: "100%",
    width: "100%",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    overflowY: "hidden",
    overflowX: "auto",
    height: "100%",
    backgroundColor: "none",
    paddingLeft: "0.5%",
    borderRadius: "none",
    borderStyle: "none",
    borderColor: "none",
    fontSize: "large",
    outline: "none",
  }),

  input: (provided, state) => ({
    ...provided,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    fontFamily: "Courier New",
    fontSize: "large",
  }),

  loadingMessage: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  noOptionsMessage: (provided, state) => ({
    ...provided,
    color: "red",
  }),

  clearIndicator: (provided, state) => ({
    ...provided,
    color: "rgb(255, 33, 3)",
    cursor: "pointer",
  }),

  loadingIndicator: (provided, state) => ({
    ...provided,
    color: "rgb(241, 97, 78)",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "rgb(241, 97, 78)",
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "rgb(241, 97, 78)",
    cursor: "pointer",
  }),

  //  Manipula as opçoes presente no campo de input após serem escolhidas.
  multiValue: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    height: "60%",
    backgroundColor: "rgba(255, 251, 251, 0.171)",
    borderRadius: "5px",
    borderColor: "rgba(255, 251, 251, 0.171)",
    minWidth: "none",
    boxShadow: "0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23)",
  }),

  //  Manipula o item de remover presente  nas opçoes  no campo de input após serem escolhidas.
  multiValueRemove: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    position: "static",
    display: "flex",
  }),

  multiValueLabel: (provided, state) => ({
    ...provided,
    alignItems: "center",
    fontSize: "80%",
    position: "static",
    textOverflow: "clip",
  }),

  //  Manipula o Drop-Down.
  menu: (provided, state) => ({
    ...provided,
    fontFamily: "Courier New",
    borderStyle: "none",
    borderColor: "none",
    borderRadius: "7px",
    backgroundColor: "rgb(179, 179, 179)",
  }),

  //  Manipula as opçoes no Drop-Down.
  option: (provided, state) => ({
    ...provided,
    borderStyle: "none",
    borderColor: "none",
    borderRadius: "2px",
    cursor: "pointer",
    backgroundColor: "rgb(179, 179, 179)",
    "&:hover": {
      backgroundColor: "rgba(226, 75, 55, 0.281)",
    },
  }),
};
