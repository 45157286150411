import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding-top: 10px;
  background-color: rgb(231, 231, 231);
  /* height: 30px; */
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 800px) {
    width: 400%;
    padding: 10px 0;
  }
`;

export const Description = styled.h5`
  align-self: center;
  margin-left: 10px;
  font-family: Courier New;
`;

export const SubContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  flex-direction: row;
  margin: 10px 0;

  @media (max-width: 800px) {
    margin: 15px 0;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
