import React, { Component } from "react";
import {
  StyledDiv,
  StyledDivTopics,
  StyledInsideDiv,
  StyledTextInsideDiv,
  StyledPTopics,
  StyledTableFooterDiv,
  StyledIconTopics,
} from "./Style";
import TableFooter from "../Table/TableFooter";
import data from "./data";
class ToUse extends Component {
  render() {
    return (
      <StyledDiv onClick={this.props.click}>
        <StyledInsideDiv>
          <StyledTextInsideDiv>
            {data[this.props.idioma].map((item, index) => (
              <div>
                <StyledDivTopics key={String(Math.random())}>
                  <StyledPTopics width="40%">{item.text}</StyledPTopics>

                  <StyledIconTopics
                    border="12px"
                    width={item.width}
                    src={item.image}
                    alt={item.alt}
                  />
                </StyledDivTopics>
                {index === 5 ? (
                  <StyledTableFooterDiv>
                    <TableFooter
                      language={this.props.idioma}
                      onHowToUse={true}
                    />
                  </StyledTableFooterDiv>
                ) : null}
              </div>
            ))}
          </StyledTextInsideDiv>
        </StyledInsideDiv>
      </StyledDiv>
    );
  }
}

export default ToUse;
