import styled from "styled-components";

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  border: 0;
  bottom: 0;
  padding: 0;
  background-color: #454546b9;
  height: 100%;
  width: 100%;

  @media (max-width: 1000px) and (orientation: landscape) {
    z-index: 1;
  }
`;

export const StyledInsideDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: rgb(231, 231, 231);
  width: 75%;
  height: 80%;
  border-radius: 8%;
  margin-bottom: 5%;

  @media (max-width: 800px) {
    width: 95%;
  }
`;

export const StyledTextInsideDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: large;
  padding: 0;
  margin-top: 0;
  border: 0;
  overflow-y: auto;
  width: 100%;
  height: 80%;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: none;
    display: none;
  }
`;

export const StyledText = styled.div`
  color: black;
  font-size: large;
  text-align: justify;
  padding-left: 3.5%;
  padding-right: 3.5%;
`;

export const StyledSubTittle = styled.p`
  font-family: Courier New;
  font-size: 20px;
  font-weight: bolder;
  color: rgb(87, 49, 1);
  padding: 0;
  margin: 0;
  margin-left: ${(props) => props.marginleft};
  margin-top: ${(props) => props.margintop};
`;

export const StyledList = styled.ul`
  list-style-type: none;
  text-align: justify;
  color: black;
  font-size: large;
  padding-right: 3.5%;
  margin-top: 2.5%;
  font-size: 100%;
  width: ${(props) => props.width};
`;
