export const getFormsDataFromLanguageCode = (languageCode) => {
  switch (languageCode) {
    case "pt":
      return {
        language: "pt",
        url: "pt/drugs/",
        label: "Selecione o princípio ativo:",
        loadingMessage: "Carregando Princípio Ativos",
        noOptionsMessage: "Princípio Ativo Não Encontrado",
        textbutton: "Validar",
      };
    case "es":
      return {
        languageresult: "es",
        url: "es/drugs/",
        label: "Ingrese uno o más principios activos:",
        loadingMessage: "Cargando principios activos",
        noOptionsMessage: "Principio Activo No Encontrado",
        textbutton: "Validar",
      };
    case "en":
      return {
        language: "en",
        url: "en/drugs/",
        label: "Enter one or more active principle:",
        loadingMessage: "Loading Active Principles",
        noOptionsMessage: "Active Principle Not Found",
        textbutton: "Validate",
      };
    default:
      return {
        language: "pt",
        url: "pt/drugs/",
        label: "Selecione o princípio ativo:",
        loadingMessage: "Carregando Princípio Ativos",
        noOptionsMessage: "Princípio Ativo Não Encontrado",
        textbutton: "Validar",
      };
  }
};
