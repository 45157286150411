const data = {
  pt: {
    HowToUse: "Como Usar",
    SeeMore: "Saiba Mais!",
    Idioma: "Idioma:",
  },
  es: {
    HowToUse: "Cómo usar",
    SeeMore: "Sepa más",
    Idioma: "Idioma:",
  },
  en: {
    HowToUse: "How to Use",
    SeeMore: "See More",
    Idioma: "Language:",
  },
};

export default data;
