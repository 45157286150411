import styled from "styled-components";

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  border: 0;
  bottom: 0;
  padding: 0;
  background-color: #454546b9;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1;
`;

export const StyledtTableContent = styled.div`
  display: flex;
  width: 90%;
  overflow-x: auto;
  background-color: transparent;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: white;
    border-radius: 10px;
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    width: 80%;
    margin-top: 20%;
    overflow-y: scroll;
  }
`;

export const Styledtable = styled.table`
  table-layout: fixed;
  width: 100%;
  /* cellpadding: 0;
  cellspacing: 0; */
  border-collapse: collapse;
  background-color: whitesmoke;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.19),
    0px 20px 20px rgba(0, 0, 0, 0.23);

  @media (max-width: 950px) {
    width: 400%;
  }
`;

// A partir daqui p baixo tudo esta dentro da styledtable.
export const Styledtr = styled.tr`
  align-items: center;
`;

export const Styledth = styled.th`
  font-family: Courier New;
  color: rgb(87, 49, 1);
  font-size: 100%;
  text-align: center;
  vertical-align: middle;
  height: 60px;
  background-color: rgb(255, 105, 86);
  border-bottom: solid 1px;
  border-color: grey;

  @media (max-width: 950px) {
    width: 150px;
  }
`;

export const Styledtd = styled.td`
  font-family: Courier New;
  color: black;
  font-size: 100%;
  text-align: center;
  vertical-align: middle;
  border-bottom: solid 1px;
  border-color: rgb(158, 158, 158);
  height: 50px;
  color: rgb(121, 68, 0);
`;

export const StyledIcon = styled.img`
  width: 15%;
`;
