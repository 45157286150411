import React, { Component } from "react";
import SeeMore from "../SeeMore/SeeMore";
import Brasil from "../../Imagens/Brasil.png";
import Espanha from "../../Imagens/Espanha.png";
import ReinoUnido from "../../Imagens/ReinoUnido.png";
import {
  StyledFooter,
  StyledAllCountryIcons,
  StyledIcon,
  StyledIconDiv,
  StyledButtons,
  StyledIconContainer,
} from "./Style";
import ToUse from "../HowToUse/ToUse";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drugInput: " ",
      showHowToUse: false,
      showSeeMore: false,
    };
    this.handleClickHowToUse = this.handleClickHowToUse.bind(this);
    this.handleClickSeeMore = this.handleClickSeeMore.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
  }

  handleClickHowToUse = (event) => {
    this.setState({ showHowToUse: true });
    event.preventDefault();
  };

  handleClickSeeMore = (event) => {
    this.setState({ showHowToUse: false, showSeeMore: true });
    event.preventDefault();
  };

  handleClickClose = (event) => {
    this.setState({ showSeeMore: false });
    this.setState({ showHowToUse: false });
    event.preventDefault();
  };

  render() {
    let showHowToUse = null;
    let showSeeMore = null;
    if (this.state.showHowToUse) {
      showHowToUse = (
        <ToUse idioma={this.props.language} click={this.handleClickClose} />
      );
    }

    if (this.state.showSeeMore) {
      showSeeMore = (
        <SeeMore
          language={this.props.language}
          clickone={this.handleClickClose}
        />
      );
    }

    return (
      <div>
        <StyledFooter>
          {showSeeMore}
          {showHowToUse}
          <StyledAllCountryIcons>
            <StyledButtons
              width="15%"
              right="2%"
              cursor="pointer"
              onClick={this.handleClickHowToUse}
            >
              {this.props.HowToUse}
            </StyledButtons>

            <StyledButtons
              width="15%"
              cursor="pointer"
              onClick={this.handleClickSeeMore}
            >
              {this.props.SeeMore}
            </StyledButtons>

            <StyledButtons width="15%" cursor="default" right="5%" left="2%">
              {this.props.Idioma}
            </StyledButtons>

            <StyledIconDiv left="2%">
              <StyledIconContainer
                width="80%"
                cursor="pointer"
                onClick={this.props.ClickBrazilTwo}
              >
                <StyledIcon width="60%" src={Brasil} alt="Portugues-BR" />
              </StyledIconContainer>
            </StyledIconDiv>

            <StyledIconDiv>
              <StyledIconContainer
                width="80%"
                cursor="pointer"
                onClick={this.props.ClickSpainTwo}
              >
                <StyledIcon
                  right="2%"
                  left="2%"
                  width="60%"
                  src={Espanha}
                  alt="Espanol-ES"
                />
              </StyledIconContainer>
            </StyledIconDiv>

            <StyledIconDiv right="1%">
              <StyledIconContainer
                width="80%"
                type="reset"
                value="Reset"
                cursor="pointer"
                onClick={this.props.ClickUnitedKingdomTwo}
              >
                <StyledIcon
                  right="2%"
                  left="2%"
                  width="64.5%"
                  src={ReinoUnido}
                  alt="English-UK"
                />
              </StyledIconContainer>
            </StyledIconDiv>
          </StyledAllCountryIcons>
        </StyledFooter>
      </div>
    );
  }
}

export default Menu;
