import React, { Component } from "react";

import MainInput from "./MainInput";
import { getFormsDataFromLanguageCode } from "./formsData";

class FormsTeste extends Component {
  render() {
    return (
      <MainInput
        loading={this.props.loading}
        startLoading={this.props.startLoading}
        finishLoading={this.props.finishLoading}
        language={this.props.language}
        formsLanguageData={getFormsDataFromLanguageCode(this.props.language)}
      />
    );
  }
}

export default FormsTeste;
