import React, { Component } from "react";
import Menu from "./Menu";
import data from "./data";
class LowerMenu extends Component {
  render() {
    return (
      <Menu
        ClickBrazilTwo={this.props.ClickBrazil}
        ClickSpainTwo={this.props.ClickSpain}
        ClickUnitedKingdomTwo={this.props.ClickUnitedKingdom}
        language={this.props.language}
        HowToUse={data[this.props.language].HowToUse}
        SeeMore={data[this.props.language].SeeMore}
        Idioma={data[this.props.language].Idioma}
      />
    );
  }
}

export default LowerMenu;
