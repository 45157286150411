import styled from "styled-components";

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  border: 0;
  bottom: 0;
  padding: 0;
  font: Helvetica;
  background-color: #454546b9;
  height: 100%;
  width: 100%;

  @media (max-width: 1000px) and (orientation: landscape) {
    z-index: 1;
  }
`;

export const StyledInsideDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 0;
  width: 75%;
  height: 80%;
  border-radius: 8%;
  background-color: rgb(231, 231, 231);
  margin-bottom: 5%;
  overflow-x: hidden;

  @media (max-width: 800px) {
    width: 90%;
    overflow-x: scroll;
    padding: 10px;
  }
`;

export const StyledTextInsideDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: large;
  padding: 0;
  margin: 0;
  border: 0;
  overflow-y: scroll;
  overflow-x: hidden;

  width: 100%;
  height: 90%;
  background-color: transparent;
  ::-webkit-scrollbar {
    width: 10px;
    background-color: none;
    display: none;
  }
`;

export const StyledTittle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
  color: black;
  background-color: transparent;
  font-weight: bolder;
  font-style: italic;
  font: Helvetica;
  padding-left: 3.5%;
  padding-right: 1.5%;
  padding-top: 3%;
  padding-bottom: 1%;
  font-size: 35px;
`;

export const StyledP = styled.p`
  font: Helvetica;
  font-weight: bold;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  margin-left: 4%;
  width: 90%;
  background-color: transparent;
  color: rgb(87, 49, 1);
`;

export const StyledDivTopics = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
  margin-left: 8%;
  margin-top: 5%;
  border: 0;
  width: 100%;
  background-color: transparent;
`;

export const StyledPTopics = styled.div`
  color: rgb(87, 49, 1);
  font-weight: bold;
  font: Helvetica;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  margin-right: 5%;
  width: ${(props) => props.width};

  @media (max-width: 800px) {
    margin: 30px 10px;
  }
`;

export const StyledIconTopics = styled.img`
  background-color: transparent;
  width: ${(props) => props.width};
  border-radius: ${(props) => props.border};

  @media (max-width: 800px) {
    width: 40%;
  }
`;

export const StyledTableFooterDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 53%;
  margin-top: 2%;
  padding: 5px;
  width: 35%;

  @media (max-width: 1100px) {
    width: 85%;
    margin: 5px 8%;
  }
`;

export const StyledIcon = styled.img`
  background-color: transparent;
  width: 60%;
`;

export const StyledButtons = styled.button`
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
`;
